<template>
  <div>
    <s-header :hasBack="true" :title="'打印数据'" />

    <div class="in main">
      <h3><van-icon name="notes-o" /><span>累计文档数据</span></h3>
      <ul class="grid">
        <li
          v-for="(item, index) in fileData"
          :style="{
            backgroundImage: `linear-gradient(90deg,${color[index]})`,
          }"
        >
          <p>{{ item.text }}</p>
          <p>{{ item.value }}个</p>
        </li>
      </ul>

      <h3><van-icon name="chart-trending-o" /><span>近7日数据分布</span></h3>
      <Seven></Seven>

      <div class="selectDate">
        <h3><van-icon name="bar-chart-o" /><span>每日数据</span></h3>
        <div @click="showDate = true">
          <van-icon name="calendar-o" /><span>{{ timer }}</span>
        </div>
      </div>

      <table class="dayData">
        <thead>
          <tr>
            <td>日期</td>
            <td>打印且创建学案</td>
            <td>仅打印</td>
            <td>仅创建学案</td>
            <td>打印份数</td>
            <td>打印张数</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tableDat">
            <td>{{ item.day }}</td>
            <td>{{ item.c1 }}</td>
            <td>{{ item.c2 }}</td>
            <td>{{ item.c3 }}</td>
            <td>{{ item.countDmCopies }}</td>
            <td>{{ item.pageNum }}</td>
          </tr>
          <tr v-if="!tableDat.length">
            <td colspan="6">
              <van-empty
                :image="require('@/assets/images/kong.png')"
                image-size="80"
                description="暂无数据"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="paging">
        <van-pagination
          v-model="page"
          :total-items="total"
          :show-page-size="5"
          force-ellipses
          @change="changePage"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>

    <van-calendar
      :min-date="minDate"
      v-model:show="showDate"
      type="range"
      @confirm="onConfirmDate"
      color="#18a4e0"
    />
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import sHeader from "@/components/SimpleHeader";
import Seven from "@/views/chart/PrintData.vue";
import { getMyDate } from "@/common/js/utils.js";
import {
  apiGetPrintStatistics,
  apiGetDayPrintData,
} from "@/service/teacher1.4.js";

let color = [
  "rgb(236,189,103),rgb(244,133,21)",
  "rgb(7,192,65),rgb(90,228,111)",
  "rgb(196,147,229),rgb(175,47,211)",
];
onBeforeMount(() => {
  getStatistics();
  getTableData();
});

let showDate = ref(false);

let fileData = ref([]);
const getStatistics = async () => {
  let res = await apiGetPrintStatistics();
  if (res) {
    res.data.all.forEach((item) => {
      fileData.value.push(
        { text: "总上传文档", value: item.count },
        { text: "总打印文档", value: item.printCount },
        { text: "总创建学案", value: item.caseNum }
      );
    });
  }
};

let minDate = ref(new Date("2000-01-01"));
let timer = ref("选择日期");
let startTime = ref("");
let startTimeTemp = ref();
let endTimeTemp = ref();
let endTime = ref("");
const onConfirmDate = async (e) => {
  startTime.value = getMyDate(e[0].getTime());
  startTimeTemp.value = e[0].getTime() / 1000;
  endTime.value = getMyDate(e[1].getTime());
  endTimeTemp.value = e[1].getTime() / 1000;
  timer.value = startTime.value + "至" + endTime.value;
  showDate.value = false;
  await getTableData();
};

let tableDat = ref([]);
let page = ref(1);
let pageSize = 10;
let total = ref(0);
const getTableData = async () => {
  let res = await apiGetDayPrintData({
    page: page.value,
    pageSize: pageSize,
    startTime: startTimeTemp.value,
    endTime: endTimeTemp.value,
  });
  if (res) {
    tableDat.value = res.data.list;
    total.value = res.data.total[0].total;
  }
};

const changePage = async (e) => {
  page.value = e;
  await getTableData();
};
</script>

<style lang="less" scoped>
@import "@/common/style/adapting.less";

.main {
  background-color: rgb(247, 244, 244);
}
:deep(.van-icon-notes-o) {
  font-size: 12px;
  margin-right: 5px;
  color: #18a4e0;
}
:deep(.van-icon-chart-trending-o) {
  font-size: 12px;
  margin-right: 5px;
  color: #18a4e0;
}
:deep(.van-icon-bar-chart-o) {
  font-size: 12px;
  margin-right: 5px;
  color: #18a4e0;
}
:deep(.van-icon-calendar-o) {
  font-size: 12px;
  margin-right: 5px;
  color: #18a4e0;
}

.grid {
  // height: 300px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-gap: 10px;
  margin: 10px 0;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  li {
    background-color: #18a4e0;
    height: 50px;
    border-radius: 6px;
    p {
      text-align: center;
      color: #fff;
      font-size: 10px;
      font-weight: 600;
    }
  }
}
.selectDate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  div:nth-child(2) {
    background-color: #fff;
    padding: 3px 5px;
    border-radius: 2px;
    font-weight: 600;
  }
}

.dayData {
  width: 100%;
  text-align: center;
  background-color: #fff;
  border-collapse: collapse;
  td {
    height: 22px;
    line-height: 22px;
    border: 0.5px solid #d7d7dd;
  }
  thead {
    background-color: rgb(4, 123, 174);
    color: #fff;
  }
}

.paging {
  width: 50%;
  font-size: 10px;
  margin: 10px auto;
}
</style>
