<template>
  <div id="seven" ref="seven"></div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import * as echarts from "echarts/core";
import {
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { apiGetSevenChart } from "@/service/teacher1.4.js";

echarts.use([
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer,
]);

let seven = ref(null);
onMounted(async () => {
  let res = await apiGetSevenChart();
  if (res) {
    var app = {};
    var chartDom = seven.value;
    var myChart = echarts.init(chartDom);
    var option;

    let arr = [["", "打印且创建学案", "仅打印", "仅创建学案"]];

    res.data.forEach((item) => {
      arr.push([item.day, item.c1, item.c2, item.c3]);
    });

    option = {
      legend: { y: "bottom", x: "center" },
      tooltip: {},
      dataset: {
        source: arr,
      },
      xAxis: { type: "category" },
      yAxis: {},
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [{ type: "bar" }, { type: "bar" }, { type: "bar" }],
    };

    option && myChart.setOption(option);
  }
});
</script>

<style lang="less" scoped>
#seven {
  height: 300px;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
}
</style>
